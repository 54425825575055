import { Input, Modal } from "edviron-ui-package";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import OTPInput from "../../../components/Otp/OTPInput";
import changePassword from "../../../assets/changePassword.svg";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SEND_OTP, GET_USER, VERIFY_PASSWORD_OTP } from "../../../Qurries";
import { toast } from "react-toastify";
import ProfileNav from "../Profile/ProfileNav";
import { dashboardContext } from "../Dashboard";

const ChangePassword = () => {
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const { password, setPassword, confirmPassword, setConfirmPassword } =
    useContext(dashboardContext);
  const [showOtp, setShowOtp] = useState(false);

  const [sendOtp, { loading, error }] = useMutation(SEND_OTP);
  const [verifyPasswordOtp, { loading: verify_loading, error: verify_error }] =
    useMutation(VERIFY_PASSWORD_OTP);
  const {
    data: user_data,
    loading: loading_data,
    error: error_loading,
  } = useQuery(GET_USER);

  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (password === "" && confirmPassword === "") {
      localStorage.removeItem("passwordChange");
      return;
    }

    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = "Changes will not be saved";
    };

    localStorage.setItem("passwordChange", "true");

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [password, confirmPassword]);

  return (
    <div className="p-[25px] min-h-full">
      <div className="bg-[#F6F8FA] w-[100%] h-[100%] p-[40px] rounded-[6px]">
        <h2 className="text-edviron_black font-bold">My Account</h2>
        <div className="flex flex-col lg:!flex-row h-[100%] pt-[40px]">
          <ProfileNav user={user_data?.getUserQuery?.role} />
          <div className="flex-1 lg:mt-0 mt-4 lg:pl-4 flex">
            <div className="lg:!w-[50%] w-full">
              <div className="relative w-full">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="floating_outlined-1"
                  className="bg-[#F6F8FA] block px-4 py-1.5 h-[48px]  w-full text-sm text-gray-900 border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
                  placeholder=" "
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <label
                  htmlFor="floating_outlined-1"
                  className="bg-[#F6F8FA] absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  New Password
                </label>
                <div className="absolute right-[12px] top-[12px]">
                  {passwordVisible ? (
                    <i
                      onClick={() => setPasswordVisible(false)}
                      className="fa-solid fa-eye-slash cursor-pointer"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setPasswordVisible(true)}
                      className="fa-solid fa-eye cursor-pointer"
                    ></i>
                  )}
                </div>
              </div>

              <div className="relative w-full mt-[20px]">
                <input
                  type="text"
                  id="floating_outlined-2"
                  className="block px-4 py-1.5 h-[48px] w-full text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
                  placeholder=" "
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <label
                  htmlFor="floating_outlined-2"
                  className="bg-[#F6F8FA] absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Confirm New Password
                </label>
              </div>

              <button
                className="rounded-[4px] text-white px-6 text-[14px] py-2 bg-edviron_black mt-[40px]"
                onClick={async () => {
                  if (password === "") {
                    toast.error("Password cannot be empty");
                    return;
                  }
                  if (password !== confirmPassword) {
                    toast.error("Password does not match");
                    return;
                  }

                  setShowOtp(true);
                  const res = await sendOtp({
                    variables: {
                      type: "reset",
                    },
                  });

                  if (res?.data.sendOtp) {
                    toast.success("OTP send successfully");
                  }
                }}
              >
                Change Password
              </button>

              {showOtp && (
                <>
                  <p className="mt-[30px] text-[14px] font-[400] text-[#1B163B]">
                    OTP has been sent to your registered email
                  </p>
                  <p className="text-[14px] font-[400] text-[#1B163B] mb-[30px]">
                    {user_data?.getUserQuery.email_id}
                  </p>

                  <OTPInput
                    length={6}
                    onComplete={async (otp: any) => {
                      if (password === "") {
                        toast.error("Password cannot be empty");
                        return;
                      }
                      if (otp === "") {
                        toast.error("OTP cannot be empty");
                        return;
                      }
                      if (password !== confirmPassword) {
                        toast.error("Password does not match");
                        return;
                      }

                      const res = await verifyPasswordOtp({
                        variables: {
                          otp: otp,
                          password: password,
                        },
                      });

                      if (res.data.verifyPasswordOtp) {
                        setConfirmPassword("");
                        setPassword("");
                        toast.success(res.data.verifyPasswordOtp);
                      }

                      setShowOtp(false);
                    }}
                    reSend={async () => {
                      return await sendOtp({
                        variables: {
                          type: "reset",
                        },
                      });
                    }}
                    btnText={"Confirm"}
                    cancel={() => {
                      setPassword("");
                      setConfirmPassword("");
                      setShowOtp(false);
                    }}
                  />
                </>
              )}
            </div>
            <div className="w-[50%] lg:flex hidden items-center justify-center">
              <img src={changePassword} alt="" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
