/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import Filters from "./components/Filters";
import { FaX } from "react-icons/fa6";
// import EdvironLogo from "../../../../assets/EdvironLogo.svg";
import { useQuery } from "@apollo/client";
import { _Table } from "../../../components/Table";
import {
  GET_ALL_SCHOOLS_QUERY,
  GET_TRANSACTION_REPORT,
} from "../../../Qurries";
import { Link } from "react-router-dom";
import { dashboardContext } from "../Dashboard";
import { LiaRupeeSignSolid } from "react-icons/lia";

function Transaction() {
  const [transactionData, setTransactionData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const { setTransaction } = useContext(dashboardContext);
  const [dateFilter, setDateFilter] = useState<{
    type: string;
    startDate: string;
    endDate: string;
  }>({
    type: "",
    startDate: new Date(0).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    endDate: new Date().toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
  });

  const [selectedTime, setSelectedTime] = useState<string>("Date");
  const [selectStatus, setSelectStatus] = useState<any>({
    Success: false,
    Pending: false,
    Failed: false,
  });
  const [transactionReportData, settransactionReportData] = useState<any>([]);
  const [schoolData, setSchoolData] = useState<
    [{ school_id: string; school_name: string }]
  >([{ school_id: "", school_name: "" }]);
  const [schoolFilter, setSchoolFilter] = useState<any>({ size: 0 });
  const [transactionTotal, setTransactionAmount] = useState(0);
  const [orderAmountTotal, setOrderAmountTotal] = useState(0);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(""),
      key: "selection",
    },
  ]);
  const {
    data: schoolsData,
    loading: schoolsLoading,
    refetch: refetchSchools,
  } = useQuery(GET_ALL_SCHOOLS_QUERY, {
    onCompleted: (data) => {
      const schools =
        schoolsData?.getAllSchoolQuery?.map(
          (school: { school_name: string; school_id: string }) => ({
            school_name: school.school_name,
            school_id: school.school_id,
          }),
        ) || [];
      setSchoolData(schools);
    },
  });

  const payment_method_map: any = {
    credit_card: "Credit Card",
    debit_card: "Debit Card",
    net_banking: "Net Banking",
    UPI: "UPI",
    upi: "UPI",
    wallet: "Wallet",
    credit_card_emi: "Credit Card EMI",
    debit_card_emi: "Debit Card EMI",
    cardless_emi: "Cardless EMI",
    pay_later: "Pay Later",
  };

  const { data: transactionReport, loading: transactionReportLoading } =
    useQuery(GET_TRANSACTION_REPORT, {
      onCompleted: (data) => {
        const formattedData = data.getTransactionReport.map((row: any) => ({
          schoolName: row.school_name,
          transactionTime: row.createdAt,
          orderID: row.collect_id,
          transactionAmount:
            row.transaction_amount === null ? "--" : row.transaction_amount,
          paymentMode:
            row.payment_method === "" || row.payment_method === null
              ? "NA"
              : payment_method_map[row.payment_method],
          orderAmount: row.order_amount === null ? "--" : row.order_amount,
          transactionStatus:
            row.status === null ? "NA" : row.status.toLowerCase(),
          schoolId: row.school_id === null ? "NA" : row.school_id,
          ...row,
        }));
        setTransactionData(formattedData);
        settransactionReportData(formattedData);
      },
    });

  const [checkboxFilter, setCheckboxFilter] = useState<any>({
    size: 0,
    status: 0,
    mode: 0,
  });

  useEffect(() => {
    const handleSearchFilter = () => {
      let resultFromSearch: any = [];
      let resultFromTimeSearch: any = [];
      let resultFromCheckBoxFilter: any = [];
      let resultFromSchoolFilter: any = [];
      if (searchText !== "") {
        transactionReportData &&
          transactionReportData.forEach((row: any) => {
            for (const key in row) {
              if (
                searchText !== "" &&
                row[key] &&
                row[key]
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toString().toLowerCase())
              ) {
                resultFromSearch.push(row);
                break;
              }
            }
          });
      } else {
        resultFromSearch = transactionReportData;
      }
      const timeSearch = resultFromSearch;
      timeSearch.forEach((row: any) => {
        let dateString = new Date(row["transactionTime"].replace(" ", "T"));
        const dateFormatter = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        const dateFormatted = dateFormatter.format(dateString);
        if (
          dateFormatted >= dateFilter.startDate &&
          dateFormatted <= dateFilter.endDate
        ) {
          resultFromTimeSearch.push(row);
        }
      });

      if (checkboxFilter.size !== 0) {
        const checkboxFiltering = resultFromTimeSearch;
        checkboxFiltering.forEach((row: any) => {
          if (
            (checkboxFilter.status === 0 ||
              (checkboxFilter.status !== 0 &&
                checkboxFilter.hasOwnProperty(
                  row?.["transactionStatus"]?.toLowerCase(),
                ))) &&
            (checkboxFilter.mode === 0 ||
              (checkboxFilter.mode !== 0 &&
                checkboxFilter.hasOwnProperty(
                  row?.["paymentMode"]?.toLowerCase(),
                )))
          ) {
            resultFromCheckBoxFilter.push(row);
          }
        });
      }

      if (schoolFilter.size !== 0) {
        const schoolFiltering =
          checkboxFilter.size !== 0
            ? resultFromCheckBoxFilter
            : resultFromTimeSearch;
        schoolFiltering.forEach((row: any) => {
          if (schoolFilter[row.schoolId]) {
            resultFromSchoolFilter.push(row);
          }
        });
      }

      setTransactionData(
        schoolFilter.size !== 0
          ? resultFromSchoolFilter
          : checkboxFilter.size !== 0
            ? resultFromCheckBoxFilter
            : resultFromTimeSearch,
      );
    };

    if (transactionReportData) {
      handleSearchFilter();
    }
  }, [
    searchText,
    checkboxFilter,
    dateFilter,
    transactionReportData,
    schoolFilter,
  ]);

  const handleDeleteTimeFilter = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(""),
        key: "selection",
      },
    ]);
    setDateFilter({
      type: "",
      startDate: "01/01/1970, 5:30:00 AM",
      endDate: new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    });
    setSelectedTime("Date");
  };

  const handleDeleteCheckBoxFilter = () => {
    setCheckboxFilter({
      size: 0,
      mode: 0,
      status: 0,
    });
    setSelectStatus({ Success: false, Pending: false, Failed: false });
  };
  const schoolFilterDeleteHandler = (school_id: string) => {
    setSchoolFilter((pre: any) => {
      delete pre[school_id];
      return { ...pre, size: pre.size - 1 };
    });
  };

  const { mode, size, status, ...allOtherFilter } = checkboxFilter;
  const handleDeleteStatusFilters = (status: string) => {
    setCheckboxFilter((pre: any) => {
      delete pre[status?.toLowerCase()];
      return { ...pre, size: pre.size - 1, status: pre.status - 1 };
    });
    status = status.charAt(0).toUpperCase() + status.substring(1);
    setSelectStatus((pre: any) => {
      return { ...pre, [status]: false };
    });
  };
  useEffect(() => {
    const amount: any = [];
    const order_amount: any = [];
    transactionData.filter((d: any) => {
      if (d.transactionStatus === "success") {
        amount.push(d.transaction_amount);
        order_amount.push(d.order_amount);
      }
    });

    setOrderAmountTotal(
      order_amount?.reduce((a: any, current: any) => a + current, 0),
    );
    setTransactionAmount(
      amount?.reduce((a: any, current: any) => a + current, 0),
    );
  }, [transactionData]);

  return (
    <div className="w-full m-4 p-2 overflow-hidden">
      <h2 className="text-[#1B163B] text-[28px] ml-4 font-[600]">
        Transactions
      </h2>
      <div className="w-full  grid grid-cols-2">
        <h2 className="text-[#1B163B] text-[24px] ml-2  font-[400]">
          Transactions Amount
        </h2>
        <h2 className="text-[#1B163B] text-[24px] ml-2  font-[400]">
          Order Amount
        </h2>
        <div className="text-[#229635] font-[400] flex items-center ">
          <span className="text-[44px] flex items-center">
            <LiaRupeeSignSolid />{" "}
            {transactionTotal !== null ? (
              <span>{transactionTotal?.toLocaleString("hi-in")}</span>
            ) : (
              <span>0</span>
            )}
          </span>
          <span className="text-[20px] text-[#717171] flex items-center ml-2">
            {` (selected period )`}
          </span>
        </div>
        <div className="text-[#229635] font-[400] flex items-center ">
          <span className="text-[44px] flex items-center">
            <LiaRupeeSignSolid />
            {orderAmountTotal !== null ? (
              <span>{orderAmountTotal?.toLocaleString("hi-in")}</span>
            ) : (
              <span>0</span>
            )}
          </span>
          <span className="text-[20px] text-[#717171] flex items-center ml-2">
            {` (selected period )`}
          </span>
        </div>
      </div>
      <div className="mx-4 mt-2 overflow-x-auto w-full">
        {transactionReportData ? (
          <_Table
            perPage={true}
            exportBtn={true}
            heading={"History"}
            pagination={true}
            copyContent={[4]}
            filter={[searchText]}
            searchBox={
              <div className="w-full flex flex-col">
                <div className="flex w-full items-center mb-2">
                  <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 max-w-md w-full rounded-lg">
                    <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                    <input
                      className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                      onFocus={(e) =>
                        (e.target.style.borderColor = "transparent")
                      }
                      type="text"
                      placeholder=" Search(Institute Name,Order ID...)"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                  <Filters
                    setCheckboxFilter={setCheckboxFilter}
                    checkboxFilter={checkboxFilter}
                    setDateFilter={setDateFilter}
                    setSelectedTime={setSelectedTime}
                    selectedTime={selectedTime}
                    setSelectStatus={setSelectStatus}
                    selectStatus={selectStatus}
                    schoolData={schoolData}
                    setSchoolFilter={setSchoolFilter}
                    schoolFilter={schoolFilter}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    transaction={true}
                  />
                </div>
                <div className="flex">
                  {dateFilter.type !== "" && (
                    <div className="bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                      <span className="text-white">{dateFilter.type}</span>
                      <FaX
                        onClick={() => handleDeleteTimeFilter()}
                        className="text-white cursor-pointer h-3"
                      />
                    </div>
                  )}
                  {checkboxFilter.mode === 0 &&
                    Object.keys(allOtherFilter).map((statusFilters: any) => {
                      return (
                        <div className="bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                          <span className="text-white">
                            {/* {Object.keys(allOtherFilter)[0].toUpperCase()} */}
                            {statusFilters}
                          </span>
                          <span>
                            <FaX
                              onClick={() =>
                                handleDeleteStatusFilters(statusFilters)
                              }
                              className="text-white cursor-pointer h-3 "
                            />
                          </span>
                        </div>
                      );
                    })}
                  {checkboxFilter.mode !== 0 && (
                    <div className=" bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                      <span className="text-white">Custom Filter</span>
                      <span>
                        <FaX
                          onClick={() => handleDeleteCheckBoxFilter()}
                          className="text-white cursor-pointer h-3"
                        />
                      </span>
                    </div>
                  )}
                  {schoolFilter.size !== 0 &&
                    Object.keys(schoolFilter).map((key: string) => {
                      if (key !== "size") {
                        return (
                          <div className=" bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                            <span className="text-white">
                              {schoolFilter[key]}
                            </span>
                            <span>
                              <FaX
                                onClick={() => schoolFilterDeleteHandler(key)}
                                className="text-white cursor-pointer h-3"
                              />
                            </span>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                </div>
              </div>
            }
            data={[
              [
                "Sr.No",
                "Institute Name",
                "Date & Time",
                "Order ID",
                "Order Amt",
                "Transaction Amt",
                "Payment Method",
                // "Commission",
                "Status",
                "Student Name",
                "Phone No.",
              ],
              ...transactionData.map((row: any, index: number) => [
                <div>{index + 1}</div>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className="truncate"
                    key={row.orderID}
                  >
                    {row.schoolName}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className=" truncate"
                    key={row.orderID}
                  >
                    {new Date(row?.transactionTime).toLocaleString("hi")}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className="truncate"
                    title={row.orderID}
                    key={row.orderID}
                  >
                    {row.custom_order_id ? row.custom_order_id : row.orderID}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >{`₹${row.orderAmount !== null ? (Math.floor(row?.orderAmount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>
                </Link>,

                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >{`₹${row.transactionAmount !== null ? (Math.floor(row?.transactionAmount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >
                    {row.paymentMode}
                  </div>
                </Link>,

                // <div className="text-center pr-4">{row?.commission}</div>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className={` capitalize ${
                      row.transactionStatus === "success"
                        ? "text-[#04B521]"
                        : row.transactionStatus === "failed"
                          ? "text-[#E54F2F]"
                          : ""
                    }`}
                    key={row.orderID}
                  >
                    {row.transactionStatus}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >
                    {row.student_name ? row.student_name : "NA"}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >
                    {row.student_phone ? row.student_phone : "NA"}
                  </div>
                </Link>,
              ]),
            ]}
          />
        ) : null}
      </div>
    </div>
  );
}
export default Transaction;
