/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from "react";
import Card from "../../../components/Card/Card";
import LineGraph from "../../../components/Graph/LineGraph";
import { useQuery } from "@apollo/client";
import {
  GET_PARTNER_PROFILE_DATA,
  GET_TRANSACTION_REPORT,
} from "../../../Qurries";

import {
  filterTransactionsByMonth,
  filterTransactionsByQuarter,
  getRecentTransactions,
  getSettlementAmount,
  sumTransactionAmountOfToday,
} from "./Helper/filterData";
import { dashboardContext } from "../Dashboard";
import { _Table } from "../../../components/Table";
import { NavLink, useNavigate } from "react-router-dom";
import RingGraph from "../../../components/RingGraph/RingGraph";
import ToolTip from "../../../components/ToolTip";
import { MdContentCopy } from "react-icons/md";
import { handleCopyContent } from "../components/school/AllSchoolList";
import { Access } from "../TeamMember/TeamMembers";

function Overview() {
  const { settlementData, commissionTotalAmount, user } =
    useContext(dashboardContext);
  const { data: partnerData, loading: partnerDataLoading } = useQuery(
    GET_PARTNER_PROFILE_DATA,
  );
  const { data: transactionReport, loading: transactionReportLoading } =
    useQuery(GET_TRANSACTION_REPORT);
  // const navigate = useNavigate();

  //   const dataByQuarter = filterTransactionsByQuarter(
  //     transactionReport?.getTransactionReport,
  //     2024,
  //     1
  //   );
  //   const dataByMonth = filterTransactionsByMonth(
  //     transactionReport?.getTransactionReport,
  //     2024,
  //     2
  //   );

  const transactionReportData = transactionReport?.getTransactionReport;

  const { sum: sumOfTodaysTransactions, count: countOfTodaysTransactions } =
    sumTransactionAmountOfToday(transactionReportData);
  const settledAmount = getSettlementAmount(
    settlementData?.getSettlementReports,
  );

  const recentTransactions = getRecentTransactions(transactionReportData);

  const amountOfSchools = partnerData?.partnerProfileData?.totalSchool || 0;
  const kycDetails = partnerData?.partnerProfileData?.kycDetails;
  return (
    <div className="mt-8">
      <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-4">
        <Card
          amount={(
            Math.floor(sumOfTodaysTransactions * 100) / 100
          ).toLocaleString("hi-in")}
          date={"Today"}
          description={"Transaction Amount"}
        />
        <Card
          amount={countOfTodaysTransactions}
          date={"Today"}
          description={"Number of transaction"}
        />
        {user?.getUserQuery.role !== Access.FINANCE_TEAM && (
          <>
            <Card
              amount={(
                Math.floor(commissionTotalAmount * 100) / 100
              ).toLocaleString("hi-in")}
              date={"Current Period"}
              description={"Total commission amount"}
            />
          </>
        )}
        <Card
          amount={amountOfSchools}
          date={"Till date"}
          description={"Total Registered Institutes"}
        />
        <Card
          amount={settledAmount}
          date={"Most Recent"}
          description={"Settlement amount"}
        />
      </div>

      <div
        style={{ gridTemplateRows: "max-content" }}
        className="grid grid-cols-3  grid-rows-2 gap-4 mb-4"
      >
        <div className=" lg:col-span-2 order-1 col-span-3 ">
          <LineGraph dataArray={transactionReportData} />
        </div>
        <div className=" lg:col-span-1 lg:order-2 order-3 col-span-3 lg:row-span-2">
          {recentTransactions ? (
            <_Table
              perPage={false}
              exportBtn={false}
              bgColor={" bg-transparent"}
              boxPadding={" p-0"}
              description={
                <div className="flex w-full justify-between text-xs pl-4 pr-1">
                  <p className="">Recent transactions</p>
                  <NavLink
                    to="/payments"
                    className="text-[#6687FF] cursor-pointer"
                  >
                    View all
                  </NavLink>
                </div>
              }
              data={[
                ["Date", "Order ID", "Amount"],
                ...recentTransactions?.map((row: any, index: number) => [
                  <div className=" max-w-[5rem]" key={row?.orderID}>
                    {new Date(row?.createdAt).toLocaleString("hi")}
                  </div>,

                  <div className="flex justify-between items-center">
                    <div
                      className="truncate max-w-[9.5rem]"
                      title={row?.collect_id}
                    >
                      {row?.collect_id}
                    </div>
                    <button
                      onClick={() => {
                        handleCopyContent(row?.collect_id);
                      }}
                    >
                      <ToolTip text="Copy Order ID">
                        <MdContentCopy
                          className="cursor-pointer text-[#717171] shrink-0 text-xl"
                          style={{
                            fontSize: "22px",
                            color: "",
                            backgroundColor: "transparent",
                          }}
                        />
                      </ToolTip>
                    </button>
                  </div>,
                  <div
                    key={row?.collect_id}
                  >{`₹${row?.transaction_amount !== null ? (Math.floor(row?.transaction_amount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>,
                ]),
              ]}
            />
          ) : null}
        </div>
        <div className="lg:col-span-2 lg:order-3 order-2 col-span-3 lg:row-span-1 row-span-2">
          <RingGraph
            kycDetails={kycDetails}
            amountOfSchools={amountOfSchools}
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
