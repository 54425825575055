/* eslint-disable react/jsx-pascal-case */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  CREATE_MEMBER,
  DELETE_MEMBER,
  GET_ALL_MEMBERS,
  SEND_OTP,
  GET_USER,
  UPDATE_ACCESS_LEVEL,
  UPDATE_MEMBER_DETAIL,
  RESET_MAIL,
} from "../../../Qurries";
import { toast } from "react-toastify";
import { _Table } from "../../../components/Table";
import { Link } from "react-router-dom";
import { Input, Modal, Form } from "edviron-ui-package";
import { FaRegEdit } from "react-icons/fa";
import Select from "react-select";
import ConfirmationBox from "../../../components/ConfermationBox";
import OTPInput from "../../../components/Otp/OTPInput";
import { Select as Select_edviron } from "edviron-ui-package";
import ProfileNav from "../Profile/ProfileNav";

export enum Access {
  ADMIN = "admin",
  MANAGEMENT = "management",
  OWNER = "owner",
  FINANCE_TEAM = "finance_team",
}

const SendResetMail = ({ email, setResetMemberPass }: any) => {
  const [resetMails, { loading, error }] = useMutation(RESET_MAIL);

  return (
    <ConfirmationBox
      setOpen={setResetMemberPass}
      funtion={async () => {
        const res = await resetMails({
          variables: {
            email: email,
          },
        });

        if (res?.data.resetMails.active) {
          toast.success("Reset mail sent successfully");
        }
      }}
      confirmationText="send reset mail"
      ButtonText="Send"
      isDanger={false}
    />
  );
};

const Action = ({
  data,
  setUpdateMemberOpen,
  setMemberData,
  role,
  setDeleteMemberOpen,
  setMemberUserId,
  setResetMemberMail,
  setResetMemberPass,
  setMemberUserName,
}: any) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <>
      {role !== Access.OWNER ? (
        "-"
      ) : (
        <div
          className="relative cursor-pointer pl-3"
          onClick={() => setShowOptions(!showOptions)}
        >
          <i className="fa-solid fa-ellipsis-vertical"></i>

          {showOptions && (
            <div
              className={`z-50 flex  flex-col fixed top-[-44px] right-[110px]`}
              onMouseLeave={() => setShowOptions(false)}
            >
              <button
                className={`py-1.5 ${
                  Access.OWNER === role ? "bg-white" : "bg-gray-400"
                } w-[100%] rounded-t-md text-xs text-[#1B163B] hover:bg-slate-100  float-right px-6`}
                onClick={() => {
                  setUpdateMemberOpen(true);
                  setMemberData(data);
                }}
                disabled={Access.OWNER !== role}
              >
                Edit
              </button>
              <button
                className={`py-1.5 ${
                  Access.OWNER === role ? "bg-white" : "bg-gray-400"
                } text-xs text-red-400 hover:bg-slate-100  float-right px-6`}
                disabled={Access.OWNER !== role}
                onClick={() => {
                  setDeleteMemberOpen(true);
                  setMemberUserId(data?._id);
                  setMemberUserName(data?.name);
                }}
              >
                Remove
              </button>
              <button
                className={`py-1.5 ${
                  Access.OWNER === role ? "bg-white" : "bg-gray-400"
                } text-xs text-[#1B163B] hover:bg-slate-100 shadow rounded-b-md  float-right px-6`}
                disabled={Access.OWNER !== role}
                onClick={() => {
                  setResetMemberMail(data?.email);
                  setResetMemberPass(true);
                }}
              >
                Reset Password
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const UpdateAccess = ({ user_id, refetch, setUpdateAccessShow }: any) => {
  const options = [
    { label: "Admin", value: Access.ADMIN },
    { label: "Management", value: Access.MANAGEMENT },
    { label: "Finance Team", value: Access.FINANCE_TEAM },
  ];

  const [selectedAccess, setSelectedAccess] = useState<any>(null);
  const [updateAccessLevel, { loading, error }] =
    useMutation(UPDATE_ACCESS_LEVEL);

  useEffect(() => {
    if (error) toast.error(error.message);
  }, [error]);

  return (
    <div className="flex flex-col items-center w-[100%] pt-[20px]">
      <div className="w-[80%]">
        <Select
          options={options}
          components={{
            IndicatorSeparator: () => null,
          }}
          onChange={(selectedOption: any) => {
            setSelectedAccess(selectedOption.value);
          }}
          styles={{
            control: (provided, state) => ({
              ...provided,
              fontSize: "12px",
            }),
            option: (provided, state) => ({
              ...provided,
              fontSize: "12px",
            }),
          }}
        />
      </div>
      <button
        className="bg-edviron_black text-[14px] rounded-md text-white float-right px-[30px] py-[8px] mt-[20px]"
        onClick={async () => {
          if (!selectedAccess) {
            toast.error("Please select access type");
            return;
          }

          const res = await updateAccessLevel({
            variables: {
              user_id: user_id,
              access: selectedAccess,
            },
          });

          if (res.data.updateAccessLevel === "Access Level updated") {
            toast.success("Access level updated");
            refetch();
            setUpdateAccessShow(false);
          }
        }}
      >
        Update Access
      </button>
    </div>
  );
};

const DeleteMember = ({
  user_id,
  refetch,
  setDeleteMemberOpen,
  memberUserName,
}: any) => {
  const [deleteMember, { loading: delete_loading, error: delete_error }] =
    useMutation(DELETE_MEMBER);

  return (
    <ConfirmationBox
      setOpen={setDeleteMemberOpen}
      funtion={async () => {
        const res = await deleteMember({
          variables: {
            user_id: user_id,
          },
        });

        if (res?.data.deleteMember.includes("deleted Successfully")) {
          toast.success("User access removed successfully");
          refetch();
        }
      }}
      confirmationText={`Remove Access of ${memberUserName}`}
      ButtonText="Remove"
    />
  );
};

const TeamMember = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_MEMBERS);
  const [
    createMember,
    { loading: create_member_loading, error: create_member_error },
  ] = useMutation(CREATE_MEMBER);
  const [
    updateMemberDetails,
    { loading: updateMemberDetails_loading, error: updateMemberDetails_error },
  ] = useMutation(UPDATE_MEMBER_DETAIL);

  const {
    data: user_data,
    loading: user_login,
    error: user_error,
  } = useQuery(GET_USER);

  useEffect(() => {
    if (error) toast.error(error.message);
  }, [error]);

  useEffect(() => {
    if (create_member_error) setNewMemberOpen(false);
  }, [create_member_error]);

  useEffect(() => {
    if (updateMemberDetails_error) setUpdateMemberOpen(false);
  }, [updateMemberDetails_error]);

  const [newMemberOpen, setNewMemberOpen] = useState(false);
  const [updateMemberOpen, setUpdateMemberOpen] = useState(false);
  const [memberData, setMemberData] = useState<any>(null);
  const [updateAccessShow, setUpdateAccessShow] = useState(false);
  const [memberUserId, setMemberUserId] = useState<any>(null);
  const [memberUserName, setMemberUserName] = useState<any>(null);

  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [role, setRole] = useState<any>({ name: "Select role" });
  const [resetMemberPass, setResetMemberPass] = useState(false);
  const [resetMembermail, setResetMemberMail] = useState<any>(null);

  const role_map: any = {
    Admin: Access.ADMIN,
    Management: Access.MANAGEMENT,
    Finance_Team: Access.FINANCE_TEAM,
  };

  // const column =
  //   user_data?.getUserQuery?.role === Access.ADMIN ||
  //   user_data?.getUserQuery?.role === Access.OWNER
  //     ? ["Name", "Email ID", "Mobile No.", "Level of Access", "Action"]
  //     : ["Name", "Email ID", "Mobile No.", "Level of Access"];

  return (
    <div className="p-[25px]">
      <div className="bg-[#F6F8FA] p-[40px] rounded-[6px]">
        <h2 className="text-edviron_black font-bold">My Account</h2>
        <div className="flex h-full flex-col lg:!flex-row  pt-[40px]">
          <Modal
            open={resetMemberPass}
            setOpen={setResetMemberPass}
            title="Reset Password"
          >
            <SendResetMail
              email={resetMembermail}
              setResetMemberPass={setResetMemberPass}
            />
          </Modal>
          <Modal
            open={deleteMemberOpen}
            setOpen={setDeleteMemberOpen}
            className="max-w-2xl"
            title="Remove Access"
          >
            <DeleteMember
              user_id={memberUserId}
              owner_mail={user_data?.getUserQuery.email_id}
              refetch={refetch}
              setDeleteMemberOpen={setDeleteMemberOpen}
              memberUserName={memberUserName}
            />
          </Modal>
          <Modal
            open={updateAccessShow}
            setOpen={setUpdateAccessShow}
            title="Update Access"
          >
            <UpdateAccess
              user_id={memberUserId}
              refetch={refetch}
              setUpdateAccessShow={setUpdateAccessShow}
            />
          </Modal>
          <Modal
            open={updateMemberOpen}
            setOpen={setUpdateMemberOpen}
            title="Update Member Details"
          >
            <Form
              onSubmit={async (data: any) => {
                if (!data.Name && !data.Email && !data["Phone Number"]) {
                  toast.error("Details not modified");
                  return;
                }
                let temp = { ...memberData };
                setMemberData({ memberData, name: data.name });
                if (data.Name) temp["name"] = data.Name;
                if (data.Email) temp["email"] = data.Email;
                if (data["Phone Number"])
                  temp["phone_number"] = data["Phone Number"];

                const res = await updateMemberDetails({
                  variables: {
                    name: temp.name,
                    user_id: memberData._id,
                    email: temp.email,
                    phone_number: temp.phone_number,
                  },
                });

                if (res?.data?.updateMemberDetails) {
                  toast.success(res?.data?.updateMemberDetails);
                  refetch();
                  setUpdateMemberOpen(false);
                }
              }}
            >
              <Input
                type="text"
                placeholder="Name"
                name="Name"
                value={memberData?.name}
                add_error={() => {}}
                required
              />
              <Input
                type="email"
                placeholder="Email ID"
                name="Email"
                value={memberData?.email}
                add_error={() => {}}
                required
              />
              <Input
                type="number"
                maxLength={10}
                placeholder="Phone No."
                name="Phone Number"
                value={memberData?.phone_number}
                add_error={() => {}}
                required
              />

              <div className="mt-2 mb-2 text-center">
                <button className="py-2 px-6 rounded-[4px] text-[14px] max-w-[15rem] w-full mt-[10px] bg-edviron_black text-white">
                  Update
                </button>
              </div>
            </Form>
          </Modal>
          <Modal
            open={newMemberOpen}
            setOpen={setNewMemberOpen}
            title="Add New Member"
          >
            <Form
              onSubmit={async (data: any) => {
                if (role.name === "select role") {
                  toast.error("Please select role");
                  return;
                }
                if (data.Password !== data["Confirm Password"]) {
                  toast.error("Password doest not match");
                  return;
                }

                const res = await createMember({
                  variables: {
                    name: data.Name,
                    email: data.Email,
                    phone_number: data["Phone Number"],
                    access: role_map[role.name],
                    password: data.Password,
                  },
                });

                if (res?.data?.createMember) {
                  toast.success(res?.data?.createMember);
                  refetch();
                  setNewMemberOpen(false);
                }
              }}
            >
              <Input
                type="name"
                placeholder="Name"
                name="Name"
                add_error={() => {}}
                required
              />
              <Input
                type="email"
                placeholder="Email ID"
                name="Email"
                add_error={() => {}}
                required
              />
              <Input
                type="number"
                maxLength={10}
                placeholder="Phone No."
                name="Phone Number"
                add_error={() => {}}
                required
              />
              <Select_edviron
                options={["Admin", "Management", "Finance_Team"].map((val) => {
                  return { name: val };
                })}
                selected={role}
                setSelected={setRole}
                label="Select Role"
              />
              <Input
                type="text"
                placeholder="Password"
                name="Password"
                add_error={() => {}}
                required
              />
              <Input
                type="text"
                placeholder="Confirm Password"
                name="Confirm Password"
                add_error={() => {}}
                required
              />

              <div className="mt-2 mb-2 text-center">
                <button className="py-2 px-6 max-w-[15rem] w-full mt-[10px] rounded-[4px] bg-edviron_black text-white">
                  Submit
                </button>
              </div>
            </Form>
          </Modal>

          <ProfileNav user={user_data?.getUserQuery?.role} />
          <div className="flex-1 flex flex-col items-center">
            {data?.getAllMembers ? (
              <_Table
                className="bg-[#ffffff]"
                heading={"Your Team"}
                pagination={true}
                searchBox={
                  <div className="flex justify-end items-center gap-x-2 w-full">
                    <div className="flex ml-auto">
                      {user_data?.getUserQuery.role === Access.OWNER && (
                        <button
                          onClick={() => setNewMemberOpen(true)}
                          className={`py-2 ${
                            user_data?.getUserQuery.role === Access.OWNER
                              ? "bg-edviron_black"
                              : "bg-gray-400"
                          } text-[14px] rounded-[4px] text-white float-right px-6 ml-2`}
                          disabled={
                            user_data?.getUserQuery.role !== Access.OWNER
                          }
                        >
                          + Add New Member
                        </button>
                      )}
                    </div>
                  </div>
                }
                data={[
                  [
                    "Name",
                    "Email ID",
                    "Mobile No.",
                    "Level of Access",
                    "Action",
                  ],
                  ...data?.getAllMembers.map((d: any, i: number) => [
                    <div className="trucate">{d.name}</div>,
                    <div className="truncate">{d.email}</div>,
                    <div>{d.phone_number}</div>,
                    <div className="flex justify-between max-w-[8rem] items-center">
                      <span className="mr-[5px] capitalize">
                        {d.access.replace("_", " ")}
                      </span>
                      <div
                        onClick={() => {
                          setUpdateAccessShow(true);
                          setMemberUserId(d._id);
                        }}
                      >
                        {user_data?.getUserQuery.role === Access.OWNER && (
                          <FaRegEdit
                            style={{ color: "#717171", cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>,
                    <Action
                      data={d}
                      setUpdateMemberOpen={setUpdateMemberOpen}
                      setMemberData={setMemberData}
                      role={user_data?.getUserQuery.role}
                      setDeleteMemberOpen={setDeleteMemberOpen}
                      setMemberUserId={setMemberUserId}
                      setResetMemberMail={setResetMemberMail}
                      setResetMemberPass={setResetMemberPass}
                      setMemberUserName={setMemberUserName}
                    />,
                  ]),
                ]}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
