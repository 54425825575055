import { Form, Input, Modal, Sidebar, SidebarItem } from "edviron-ui-package";
import React, { useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { RiAccountPinCircleFill } from "react-icons/ri";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  SEND_OTP,
  GET_USER,
  UPDATE_PROFILE_DETAIL,
  UPDATE_TRUSTEE_MAIL,
  UPDATE_TRUSTEE_PHONE_NUMBER,
} from "../../../Qurries";
import { toast } from "react-toastify";
import OTPInput from "../../../components/Otp/OTPInput";
import ConfirmationBox from "../../../components/ConfermationBox";
import { Access } from "../TeamMember/TeamMembers";
import ProfileNav from "./ProfileNav";

const Profile = () => {
  const {
    data: user_data,
    loading: user_loading,
    error: user_error,
    refetch,
  } = useQuery(GET_USER);
  const [name, setName] = useState<any>(null);
  const [brandName, setBrandName] = useState<any>(null);

  const [email, setEmail] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState<any>(null);

  useEffect(() => {
    if (user_data) {
      setName(user_data?.getUserQuery.name);
      setBrandName(user_data?.getUserQuery.brand_name);
      setEmail(user_data?.getUserQuery.email_id);
      setPhoneNumber(user_data?.getUserQuery.phone_number);
    }
  }, [user_data]);

  const [isEdit, setIsEdit] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [phoneOpen, setPhoneOpen] = useState(false);

  const [updateProfileDetails, { loading, error }] = useMutation(
    UPDATE_PROFILE_DETAIL,
  );

  const EmailUpdate = ({ refetch, setEmailOpen }: any) => {
    const [updateTrusteeMail, { loading, error }] =
      useMutation(UPDATE_TRUSTEE_MAIL);
    const [sendOtp, { loading: otp_loading, error: otp_error }] =
      useMutation(SEND_OTP);
    const [otpOpen, setOtpOpen] = useState<any>(false);
    const [updatedEmail, setUpdatedEmail] = useState<any>(email);

    return (
      <div>
        <div className="relative w-full mt-[20px]">
          <input
            type="text"
            id="floating_outlined-2"
            className="block px-4 py-1.5 h-[48px] w-full text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
            placeholder=" "
            value={updatedEmail}
            disabled={otpOpen}
            onChange={(e) => {
              setUpdatedEmail(e.target.value);
            }}
          />
          <label
            htmlFor="floating_outlined-2"
            className="bg-[#FFFFFF] absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Email
          </label>
        </div>

        <div className="mt-2 mb-2">
          <button
            className="rounded-[6px] py-[8px] text-white text-[14px] bg-edviron_black mt-[10px] w-[109px] mb-[25px]"
            onClick={async () => {
              if (!updatedEmail || updatedEmail.trim() === "") {
                toast.error("Email cannot be empty");
                return;
              }
              if (updatedEmail.trim() === email) {
                toast.error("Please enter different email");
                return;
              }
              setOtpOpen(true);
              const res = await sendOtp({
                variables: {
                  type: "email",
                },
              });

              if (res.data.sendOtp) {
                toast.success("OTP sent successfully");
              }
            }}
          >
            Update
          </button>
        </div>
        {otpOpen && (
          <OTPInput
            length={6}
            onComplete={async (val: any) => {
              const res = await updateTrusteeMail({
                variables: {
                  email: updatedEmail,
                  otp: val,
                },
              });

              if (res?.data.updateTrusteeMail) {
                toast.success(res?.data.updateTrusteeMail);
                refetch();
                setEmailOpen(false);
              }
            }}
            reSend={async () => {
              return await sendOtp({
                variables: {
                  type: "email",
                },
              });
            }}
            cancel={() => {
              setEmailOpen(false);
            }}
          />
        )}
      </div>
    );
  };

  const PhoneNumberUpdate = ({ refetch, setPhoneOpen }: any) => {
    const [updateTrusteePhoneNumber, { loading, error }] = useMutation(
      UPDATE_TRUSTEE_PHONE_NUMBER,
    );
    const [sendOtp, { loading: otp_loading, error: otp_error }] =
      useMutation(SEND_OTP);
    const [otpOpen, setOtpOpen] = useState<any>(null);
    const [updatedPhoneNumber, setUpdatedPhoneNumber] =
      useState<any>(phoneNumber);

    return (
      <div>
        <div className="relative w-full mt-[20px]">
          <input
            type="number"
            id="floating_outlined-2"
            className="block px-4 py-1.5 h-[48px] w-full text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
            placeholder=" "
            value={updatedPhoneNumber}
            disabled={otpOpen}
            onChange={(e) => {
              if (e.target.value.length > 10) return;
              setUpdatedPhoneNumber(e.target.value);
            }}
          />
          <label
            htmlFor="floating_outlined-2"
            className="bg-[#FFFFFF] absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Phone Number
          </label>
        </div>

        <div className="mt-2 mb-2">
          <button
            className="rounded-[6px] py-[8px] text-white text-[14px] bg-edviron_black mt-[10px] w-[109px] mb-[25px]"
            onClick={async () => {
              if (!updatedPhoneNumber || updatedPhoneNumber.trim() === "") {
                toast.error("Phone number cannot be empty");
                return;
              }
              if (updatedPhoneNumber.trim() === phoneNumber) {
                toast.error("Please enter different phone number");
                return;
              }
              setOtpOpen(true);
              const res = await sendOtp({
                variables: {
                  type: "phone",
                },
              });

              if (res.data.sendOtp) {
                toast.success("OTP sent successfully");
              }
            }}
          >
            Update
          </button>
        </div>

        {otpOpen && (
          <OTPInput
            length={6}
            onComplete={async (val: any) => {
              const res = await updateTrusteePhoneNumber({
                variables: {
                  phone_number: updatedPhoneNumber,
                  otp: val,
                },
              });

              if (res?.data.updateTrusteePhoneNumber) {
                toast.success(res?.data.updateTrusteePhoneNumber);
                refetch();
                setPhoneOpen(false);
              }
            }}
            reSend={async () => {
              return await sendOtp({
                variables: {
                  type: "email",
                },
              });
            }}
            cancel={() => {
              setPhoneOpen(false);
            }}
          />
        )}
      </div>
    );
  };

  function capitalizeFirstLetter(str: string) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  return (
    <div className="p-[25px] min-h-[90%]">
      <div className="bg-[#F6F8FA] w-[100%] h-[100%] p-[40px] rounded-[6px]">
        <Modal
          open={emailOpen}
          setOpen={() => {
            setEmailOpen(false);
            setEmail(user_data?.getUserQuery.email_id);
          }}
          title="Edit Email"
        >
          <EmailUpdate refetch={refetch} setEmailOpen={setEmailOpen} />
        </Modal>

        <Modal
          open={phoneOpen}
          setOpen={setPhoneOpen}
          title="Update Phone Number"
        >
          <PhoneNumberUpdate refetch={refetch} setPhoneOpen={setPhoneOpen} />
        </Modal>

        <h2 className="text-edviron_black font-bold">My Account</h2>
        <div className="flex h-full flex-col lg:!flex-row  pt-[40px]">
          <ProfileNav user={user_data?.getUserQuery?.role} />
          <div className="flex-1 lg:pl-4 flex flex-col lg:items-center lg:mt-0 mt-4 relative">
            {isEdit && (
              <div className="w-full mb-[10px]">
                <p className="text-[14px] text-[#0A1]">
                  You can edit the details now. Click on Update to save the
                  changes.
                </p>
              </div>
            )}
            <div
              className={`w-full ${
                !isEdit ? "flex justify-between" : ""
              } p-[20px] bg-[#F6F8FA] profile-shadow rounded-[20px]`}
            >
              <div className="relative flex items-center content-center">
                <RiAccountPinCircleFill size={70} />
                <div className="ml-[20px]">
                  {isEdit ? (
                    <div className="flex items-end">
                      <input
                        type="text"
                        value={name}
                        className="border-b-[1px] border-[#717171] bg-[#F6F8FA] text-[14px] text-[#6687FF] focus:outline-none"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <p className="text-[10px] text-[#717171] ml-[8px]">
                        (edit)
                      </p>
                    </div>
                  ) : (
                    <p className="text-[#1B163B] font-[600] text-[14px]">
                      {user_data?.getUserQuery.name &&
                      user_data?.getUserQuery.name
                        ? user_data?.getUserQuery.name
                        : "NA"}
                    </p>
                  )}
                  <p className="text-[#717171] text-[12px]">
                    {capitalizeFirstLetter(user_data?.getUserQuery.role) &&
                    capitalizeFirstLetter(user_data?.getUserQuery.role)
                      ? capitalizeFirstLetter(user_data?.getUserQuery.role)
                      : "NA"}
                  </p>
                  {isEdit ? (
                    <div className="flex items-end">
                      <input
                        type="text"
                        className="border-b-[1px] border-[#717171] bg-[#F6F8FA] text-[14px] text-[#6687FF] focus:outline-none"
                        value={brandName}
                        onChange={(e) => {
                          setBrandName(e.target.value);
                        }}
                      />
                      <p className="text-[10px] text-[#717171] ml-[8px]">
                        (edit)
                      </p>
                    </div>
                  ) : (
                    <p className="text-[#1B163B] text-[14px]">
                      {user_data?.getUserQuery.brand_name &&
                      user_data?.getUserQuery.brand_name
                        ? user_data?.getUserQuery.brand_name
                        : "NA"}
                    </p>
                  )}
                </div>
              </div>
              {user_data?.getUserQuery.role === Access.OWNER ? (
                !isEdit ? (
                  <div
                    className="flex items-center content-center cursor-pointer"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  >
                    <FaRegEdit size={12} style={{ color: "#717171" }} />
                    <p className="text-[#717171] ml-[5px] text-[12px]">Edit</p>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <button
                      className="rounded-[4px] text-[14px] border-[0.5px] border-[#717171] mt-[10px] mr-[10px] text-[#717171] w-[96px] h-[33px]"
                      onClick={() => {
                        setIsEdit(false);
                        setName(user_data?.getUserQuery.name);
                      }}
                    >
                      Discard
                    </button>
                    <button
                      className="rounded-[4px] text-white text-[14px] mt-[10px] bg-edviron_black w-[96px] h-[33px]"
                      onClick={async () => {
                        if (
                          name.trim() === user_data?.getUserQuery.name &&
                          brandName.trim() ===
                            user_data?.getUserQuery.brand_name
                        ) {
                          toast.error("Details not modified");
                          return;
                        }
                        const res = await updateProfileDetails({
                          variables: {
                            name: name,
                            brand_name: brandName,
                          },
                        });

                        if (
                          res?.data.updateProfileDetails ===
                          "User updated successfully"
                        ) {
                          toast.success("User details updated successfully");
                          refetch();
                          setIsEdit(false);
                        }
                      }}
                    >
                      Update
                    </button>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
            <div className="w-full grid lg:!grid-cols-2 grid-cols-1 px-8 py-[20px] bg-[#F6F8FA] profile-shadow rounded-[20px] mt-5">
              <div className="py-[10px]">
                <div className="flex w-[250px] justify-between">
                  <p className="text-[#767676] text-[10px]">Email</p>
                  {user_data?.getUserQuery.role === "owner" && (
                    <div
                      onClick={() => {
                        setEmailOpen(true);
                      }}
                    >
                      <FaRegEdit
                        size={12}
                        style={{
                          color: "#717171",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
                <p className="text-[#1B163B] text-[14px]">
                  {user_data?.getUserQuery.email_id}
                </p>
              </div>
              <div className="py-[10px]">
                <p className="text-[#767676] text-[10px]">Position</p>
                <p className="text-[#1B163B] capitalize text-[14px]">
                  {user_data?.getUserQuery.role.replace("_", " ")}
                </p>
              </div>
              <div className="py-[10px]">
                <div className="flex w-[250px] justify-between">
                  <p className="text-[#767676] text-[10px]">Contact</p>
                  {user_data?.getUserQuery.role === "owner" && (
                    <div
                      onClick={() => {
                        setPhoneOpen(true);
                      }}
                    >
                      <FaRegEdit
                        size={12}
                        style={{
                          color: "#717171",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
                <p className="text-[#1B163B] text-[14px]">
                  {user_data?.getUserQuery.phone_number}
                </p>
              </div>
              <div className="py-[10px]">
                <p className="text-[#767676] text-[10px]">
                  Company registered name
                </p>
                <p className="text-[#1B163B] text-[14px]">
                  {user_data?.getUserQuery.name}
                </p>
              </div>
            </div>
            <p className=" mt-auto text-[10px] text-[#767676]">
              Your personal information is securely protected and treated with
              the utmost confidentiality.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
