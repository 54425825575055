import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
interface CheckboxProps {
  id: string;
  label: string;
  setCheckboxFilter: any;
  name: string;
  checkBoxFilter: any;
  filter: any;
  setFilter: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  setCheckboxFilter,
  name,
  checkBoxFilter,
  filter,
  setFilter,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFilter((prevFilter: any) => {
      if (checked) {
        return {
          ...prevFilter,
          [value.toLowerCase()]: checked,
          size: prevFilter.size + 1,
          status: name === "Status" ? prevFilter.status + 1 : prevFilter.status,
          mode:
            name === "paymentMethods" ? prevFilter.mode + 1 : prevFilter.mode,
        };
      } else {
        const { [value.toLowerCase()]: omittedField, ...updatedFilter } =
          prevFilter;
        return {
          ...updatedFilter,
          size: prevFilter.size - 1,
          status: name === "Status" ? prevFilter.status - 1 : prevFilter.status,
          mode:
            name === "paymentMethods" ? prevFilter.mode - 1 : prevFilter.mode,
        };
      }
    });
    setIsChecked(!isChecked);
  };
  return (
    <div>
      <input
        type="checkbox"
        id={id}
        checked={filter.hasOwnProperty(id.toLowerCase())}
        name={name}
        value={id}
        onChange={handleCheckboxChange}
      />
      <label
        htmlFor={id}
        className={`${
          filter.hasOwnProperty(id.toLowerCase()) ? "text-black" : ""
        } pl-2`}
      >
        {label}
      </label>
    </div>
  );
};
function AllFilters(props: any) {
  const [filterOpt, setFilterOpt] = useState<any>({
    status: true,
    mode: false,
    school: false,
  });
  const [schoolData, setSchoolData] = useState<any>([...props.schoolData]);
  const checkboxItemsStatus = [
    { id: "Success", label: "Success" },
    { id: "Pending", label: "Pending" },
    { id: "Failed", label: "Failed" },
  ];
  const paymentMethodItems = [
    { id: "Credit Card", label: "Credit Card" },
    { id: "Credit Card EMI", label: "Credit Card EMI" },
    { id: "UPI", label: "UPI" },
    { id: "Wallet", label: "Wallet" },
    { id: "Pay Later", label: "Pay Later" },
    { id: "Cardless EMI", label: "Cardless EMI" },
    { id: "Net Banking", label: "Net Banking" },
    { id: "Debit Card EMI", label: "Debit Card EMI" },
  ];

  const [filter, setFilter] = useState<any>({
    ...props.checkboxFilter,
  });

  const applyButtonHandler = () => {
    props.setCheckboxFilter({
      ...filter,
    });
  };

  const toggleDropdown = (dropdownName: string) => {
    const updatedDropdowns = Object.keys(filterOpt).reduce((acc: any, name) => {
      acc[name] = name === dropdownName ? !filterOpt[name] : false;
      return acc;
    }, {});
    setFilterOpt(updatedDropdowns);
  };
  const clearFilter = () => {
    props.setCheckboxFilter((pre: any) => {
      return { size: 0, status: 0, mode: 0 };
    });
  };

  const schoolSearchHandler = (str: string) => {
    if (!str) {
      setSchoolData(props.schoolData);
      return;
    }
    const data = props.schoolData.filter((school: any) => {
      if (school.school_name.toLowerCase().includes(str.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });
    setSchoolData(data);
  };
  return (
    <div className="absolute text-sm z-10 right-0 mt-2 flex bg-white shadow-lg rounded-lg px-4 min-w-[50rem] min-h-[30rem] h-[20rem]">
      <div className="flex-col items-center  p-4 ">
        <p className="text-lg mb-4 text-[#6F6AF8]">Filter</p>
        <button
          onClick={() => toggleDropdown("status")}
          className={`min-w-[8rem] ${
            filterOpt.status ? "bg-[#DADBFC]" : ""
          } rounded-xl p-1 flex items-center mb-4 `}
        >
          <span className="pl-2 ">Status</span>
          <IoIosArrowForward className="w-4 h-4 ml-auto block" />
        </button>
        <button
          onClick={() => toggleDropdown("mode")}
          className={`min-w-[8rem] ${
            filterOpt.mode ? "bg-[#DADBFC]" : ""
          } rounded-xl p-1 flex items-center mb-4 `}
        >
          <span className="pl-2 ">Mode</span>
          <IoIosArrowForward className="w-4 h-4 ml-auto block" />
        </button>
        <button
          onClick={() => toggleDropdown("school")}
          className={`min-w-[8rem] ${
            filterOpt.school ? "bg-[#DADBFC]" : ""
          } rounded-xl p-1 flex items-center mb-4 `}
        >
          <span className="pl-2 ">Institute</span>
          <IoIosArrowForward className="w-4 h-4 ml-auto block" />
        </button>
      </div>
      <div className="w-[1px] h-auto bg-gray-300"></div>
      <div
        className={`${filterOpt.status ? "flex flex-col w-full" : "hidden"}`}
      >
        <div className="w-max ml-8 grid grid-cols-3 grid-rows-3 h-fit gap-x-20 mt-4 text-slate-400">
          {checkboxItemsStatus.map((item) => (
            <Checkbox
              key={item.id}
              id={item.id}
              checkBoxFilter={props.checkboxFilter}
              label={item.label}
              setCheckboxFilter={props.setCheckboxFilter}
              name="Status"
              filter={filter}
              setFilter={setFilter}
            />
          ))}
        </div>
        <div className="flex mt-auto ml-auto mb-3">
          <button
            onClick={() => clearFilter()}
            className="border mr-4 p-1.5 min-w-[6rem] rounded-md text-sm text-[#6687FF] border-edviron_black"
          >
            Clear
          </button>
          <button
            onClick={() => applyButtonHandler()}
            disabled={filter.size === 0}
            className={`border p-1.5 min-w-[6rem] rounded-md text-sm ${
              filter.size === 0
                ? "bg-[#EEF1F6] text-slate-700"
                : "text-white bg-edviron_black"
            } `}
          >
            Apply
          </button>
        </div>
      </div>
      <div className={`${filterOpt.mode ? "flex flex-col w-full" : "hidden"}`}>
        <div className="w-max ml-8 grid grid-cols-3 grid-rows-3 h-fit gap-y-4 gap-x-12 mt-4 text-slate-400">
          {paymentMethodItems.map((item) => (
            <Checkbox
              checkBoxFilter={props.checkboxFilter}
              key={item.id}
              id={item.id}
              label={item.label}
              setCheckboxFilter={props.setCheckboxFilter}
              name="paymentMethods"
              filter={filter}
              setFilter={setFilter}
            />
          ))}
        </div>
        <div className="flex mt-auto ml-auto mb-3">
          <button
            onClick={() => clearFilter()}
            className="border mr-4 p-1.5 min-w-[6rem] rounded-md text-sm text-[#6687FF] border-edviron_black"
          >
            Clear
          </button>
          <button
            onClick={() => applyButtonHandler()}
            disabled={filter.size === 0}
            className={`border p-1.5 min-w-[6rem] rounded-md text-sm ${
              filter.size === 0
                ? "bg-[#EEF1F6] text-slate-700"
                : "text-white bg-edviron_black"
            } `}
          >
            Apply
          </button>
        </div>
      </div>
      <div
        className={`${
          filterOpt.school ? "w-full flex flex-col items-center" : "hidden"
        }`}
      >
        <div className="flex gap-x-2 bg-[#EEF1F6] items-center p-2 rounded-lg w-[90%] mt-4">
          <IoSearch className="w-4 h-4 mr-2" />
          <input
            type="text"
            placeholder=" Search(Institute Name)"
            value={schoolData.name}
            className=" text-[#505E8E] text-sm w-full outline-none bg-transparent"
            onChange={(e) => schoolSearchHandler(e.target.value)}
          ></input>
        </div>
        {
          <div className="mt-4 overflow-y-auto border border-gray-200 flex flex-col gap-y-2 rounded-lg w-[90%] mb-4">
            {schoolData.map((school: any) => {
              return (
                <ul
                  key={school.school_id}
                  onClick={() =>
                    props.setSchoolFilter((pre: any) => {
                      if (pre[school.school_id] === school.school_name) {
                        return {
                          ...pre,
                          [school.school_id]: school.school_name,
                        };
                      } else {
                        return {
                          ...pre,
                          [school.school_id]: school.school_name,
                          size: pre.size + 1,
                        };
                      }
                    })
                  }
                  className={`cursor-pointer p-2 ${
                    props.schoolFilter[school.school_id] === school.school_name
                      ? "bg-gray-200"
                      : ""
                  }`}
                >
                  {school.school_name}
                </ul>
              );
            })}
          </div>
        }
      </div>
    </div>
  );
}
export default AllFilters;
